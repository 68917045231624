import React, { useEffect, useState } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import Explore from "../components/atoms/explore";
import UltimateBanner from "../components/ogranisms/UltimateBanner/UltimateBanner";
import { connectlist3 } from "../constants/connectData";
import Unique from "../components/molecules/hr/unique";
import ServicesDetails from "../components/ogranisms/ServicesDetails/ServicesDetails";
import * as styles from "./styles.module.scss";
import UltimateSection from "../components/molecules/UltimateSection/UltimateSection";
import FlexBanner from "../components/atoms/FlexBanner/FlexBanner";
function UltimateServices() {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);
  });
  return (
    <>
      <Header />
      <main>
        <UltimateBanner />
        <Explore homebtn extraClass="homeSection" btnText="About us" link="/about-us" title="About our Programs" description="We believe that the way to are getting started with is crucial for your success. Our focus is on helping you find ideal areas of use, achieving immediate returns on your presentations, and providing you with access to proven templates. Let us help you get started with MASTER WiZR today and see the difference for yourself!"/>

        {width >= 768 && <ServicesDetails />}
        {width >= 768 &&
          <div className={styles.quoteconnect}>
          <h1>
            As the saying goes, "the way you start tells something about how things end," and this rings true for the importance of beginning your journey with the right options that provide comprehensive and practical solutions.
          </h1>
        </div>
        }
        <FlexBanner
          extraClass={"loginSection"}
          title={`Why we recommend the <br/> <span>Master Programs</span> `}
          image="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1680114263/web%20images/loginicons_1_1_yelocy.png"
          desc={`
            <br/> <br/> <br/>
            <ul><li><b>Time</b> - Walkthroughs will same you time</li>
            <li><b>Convenience </b> - We will brainstorm together</li>
            <li><b>Structure</b> - We have extensive experience to share  </li>
            <li><b>Premium</b> - Your material will look better</li>
            <li><b>Impact</b> - Faster to impactful restuls</li>

            </ul> 
            `}
        />
        {width >= 768 &&
        <div className={styles.quoteconnect}>
          <h1>
            The bottom line: Start your journey with Master WiZR and enjoy the benefits of our time-saving walkthroughs, brainstorming sessions for convenient solutions, proven structure from extensive experience, elevated material with premium features, and faster and more impactful results.
          </h1>
        </div>
        }
        <UltimateSection />
        <Unique
          list={connectlist3}
          image={true}
          extraClass="gridfour"
          title="Experience Optimization"
          description={`Our sales staff will provide further practical details for your success with MASTER WiZR`}
        />
        <Footer />
      </main>
    </>
  );
}
export default UltimateServices;
