import React, {useCallback, useEffect, useState} from "react";
import * as styles from './explore.module.scss';
import WizrIcon from '../../../svg/exploreWizr.svg';
import StyledButton from '../../atoms/StyledButton/index';
import {FaAngleRight } from 'react-icons/fa';
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Explore = ({ title, description,mwimage,extraClass, ...rest}) => {
    const [width,setWidth]=useState(0)
    useEffect( () => {
        setWidth(window.innerWidth);
    },[]);
    return(
  <>
  {width && width>=768 ? 
    <div className={`${styles.container} ${styles[extraClass]}`}>
        <div className={styles.contentHolder}> 
    <div>
        {mwimage ? <img className={`${styles.logo} ${styles.mwimage}`} src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708024631/Group_1321314327_1_l2u4uf.png" alt="mwimage" />
        :(
            <WizrIcon className={styles.logo} />
        )
    }
    </div>
    <div className={styles.description}>
        <span className={styles.subtitle}>{rest?.subtitle}</span>
        <h2>{title}</h2>
        <p>{description}</p>
        {rest?.homebtn ? <AniLink hex="#665781" to="/examples" paintDrip>See examples and more</AniLink>:      <StyledButton  title={rest?.btnText} link={rest?.link} extraClass="nobgLink"/>}
        <hr/>
    </div>
    </div>
</div> : <div className={`${styles.mobileContainer} ${styles[extraClass]}`}>
    <h1>{title}</h1>
    <div>
    {mwimage ? <img className={`${styles.logo} ${styles.mwimage}`} src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1708024631/Group_1321314327_1_l2u4uf.png" alt="mwimage" />
        :(
            <WizrIcon className={styles.logo} />
        )}
    </div>
    <p>{description}</p>
</div>
  }
  </>
    );
}

export default Explore;